import React from 'react';
import PropTypes from 'prop-types';
import { PortableText } from '@portabletext/react';
import './Heading.scss';
import { contentPropType } from '../../prop-types';

export default function Heading({
  title, content, position, sticky,
}) {
  return (
    <header className={`section-heading section-heading--${position} ${sticky ? 'section-heading--sticky' : ''}`}>
      <h4 className="title is-3">{title}</h4>
      {content && (
        <div className="content">
          <PortableText value={content} />
        </div>
      )}
    </header>
  );
}

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  content: contentPropType,
  position: PropTypes.oneOf(['left', 'center', 'right']),
  sticky: PropTypes.bool,
};

Heading.defaultProps = {
  position: 'center',
  sticky: false,
  content: [],
};

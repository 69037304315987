import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CertificationsSection.scss';
import { SwiperSlide, Swiper } from 'swiper/react';
import { EffectCards } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PortableText } from '@portabletext/react';
import {
  faAnglesDown, faCertificate, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Heading from '../Heading/Heading';
import { contentPropType } from '../../prop-types';

function CertificationItem({ trainingName, description }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="certifications__item">
      <div className="certifications__item__title">
        <FontAwesomeIcon icon={faCertificate} className="mr-3 has-text-warning" />
        {trainingName}
      </div>
      <div className={`certifications__item__content content ${isOpen ? 'is-active' : ''}`}>
        <PortableText value={description} />
      </div>
      <button
        className={`certifications__item__read-more ${!isOpen ? 'is-active' : ''}`}
        onClick={() => setIsOpen(true)}
        type="button"
      >
        <span>
          Show more
        </span>
        <FontAwesomeIcon icon={faAnglesDown} className="ml-2" />
      </button>
    </div>
  );
}

CertificationItem.propTypes = {
  trainingName: PropTypes.string.isRequired,
  description: contentPropType.isRequired,
};

export default function CertificationsSection({ title, content, certifications }) {
  const [swiperInstance, setSwiperInstance] = useState(null);

  return (
    <div className="section certifications-section section--full-height">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-12 is-4-desktop is-justify-content-center is-flex is-flex-direction-column">
            <Heading title={title} content={content} position="left" />
          </div>
          <div className="column is-12 is-7-desktop is-offset-0 is-offset-1-desktop">
            <div className="certifications">
              <Swiper
                effect="cards"
                grabCursor
                onSwiper={setSwiperInstance}
                modules={[EffectCards]}
              >
                {certifications.map(({ _key: key, ...rest }) => (
                  <SwiperSlide key={key}>
                    <CertificationItem {...rest} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="buttons is-centered mt-5">
                <button
                  type="button"
                  aria-label="Previous slide"
                  title="Previous"
                  className="button is-dark"
                  onClick={() => swiperInstance.slidePrev()}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  type="button"
                  aria-label="Next slide"
                  title="Next"
                  className="button is-dark"
                  onClick={() => swiperInstance.slideNext()}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CertificationsSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: contentPropType.isRequired,
  certifications: PropTypes.arrayOf(PropTypes.shape({
    _key: PropTypes.string,
    trainingName: PropTypes.string,
    description: contentPropType,
  })).isRequired,
};

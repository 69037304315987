import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import { urlFor } from '../../client';
import './ImageRowSection.scss';
import { assetPropType } from '../../prop-types';

export default function ImageRowSection({ name, images }) {
  return (
    <div className="section pl-0 pr-0">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-8">
            <Heading title={name} />
          </div>
          <div className="column is-12">
            <div className="image-row">
              {images.map(({ _key: key, image, link }) => (
                <a href={link} target="_blank" key={key} className="image-row__item" rel="noreferrer">
                  <img alt="Logo" src={urlFor(image).height(100).auto('format').url()} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ImageRowSection.propTypes = {
  name: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    image: assetPropType,
    link: PropTypes.string,
    _key: PropTypes.string,
  })).isRequired,
};

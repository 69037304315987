import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import { urlFor } from '../../client';
import intermediateIcon from '../../assets/images/intermediate.svg';
import advancedIcon from '../../assets/images/advanced.svg';
import expertIcon from '../../assets/images/expert.svg';
import './ExperiencesSection.scss';
import { assetPropType, contentPropType } from '../../prop-types';

function LevelIcon({ level }) {
  switch (level) {
    case 'intermediate':
      return <img width="25" height="14" src={intermediateIcon} alt="Intermediate" />;
    case 'advanced':
      return <img width="25" height="14" src={advancedIcon} alt="Advanced" />;
    case 'expert':
      return <img width="25" height="14" src={expertIcon} alt="Expert" />;
    default:
      return <img width="25" height="14" src={intermediateIcon} alt="Intermediate" />;
  }
}

const levelPropType = PropTypes.oneOf(['intermediate', 'advanced', 'expert']);

LevelIcon.propTypes = {
  level: levelPropType.isRequired,
};

function ExperiencesSection({ title, content, skills }) {
  return (
    <div className="section">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-12 is-4-widescreen is-flex is-align-items-center">
            <Heading position="left" title={title} content={content} />
          </div>
          <div className="column is-12 is-8-widescreen">
            <div className="skills columns is-multiline is-centered is-mobile">
              {skills.map(({
                name, icon, level, _key: key,
              }) => (
                <div key={key} className="column is-6-mobile is-mobile is-3-tablet is-3-widescreen">
                  <div className="skills__item">
                    <div className="skills__item__icon animate__animated animate__fadeInDown">
                      <img src={urlFor(icon).width(120).auto('format').url()} alt={`${name} Logo`} />
                    </div>
                    <div className="skills__item__level">
                      <LevelIcon level={level} />
                    </div>
                    <h5 className="skills__item__title">
                      {name}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ExperiencesSection);

ExperiencesSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: contentPropType.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape({
    _key: PropTypes.string,
    _type: PropTypes.string,
    icon: assetPropType,
    level: levelPropType,
    name: PropTypes.string,
  })).isRequired,
};

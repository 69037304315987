import PropTypes from 'prop-types';

export const iconPropType = PropTypes.shape({
  iconName: PropTypes.string,
  prefix: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])),
});

export const socialsPropType = PropTypes.arrayOf(PropTypes.shape({
  link: PropTypes.string,
  icon: iconPropType,
  title: PropTypes.string,
}));

export const assetPropType = PropTypes.shape({
  _type: PropTypes.string,
  asset: PropTypes.shape({
    _ref: PropTypes.string,
    _type: PropTypes.string,
  }),
});

export const configPropType = PropTypes.shape({
  socials: socialsPropType,
  email: PropTypes.string,
  cv: assetPropType,
  dateOfBirth: PropTypes.string,
});

export const contentPropType = PropTypes.arrayOf(PropTypes.shape({
  _key: PropTypes.string,
  _type: PropTypes.string,
  markDefs: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    _key: PropTypes.string,
    _type: PropTypes.string,
  })),
  style: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape({
    _key: PropTypes.string,
    _type: PropTypes.string,
    marks: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string,
  })),
}));

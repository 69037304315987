import { PortableText } from '@portabletext/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { fileUrlFor, urlFor } from '../../client';
import './HeroSection.scss';
import { configPropType, contentPropType, assetPropType } from '../../prop-types';
import Socials from '../Socials/Socials';

function HeroSection({
  buttonText, content, image, config,
}) {
  return (
    <section className="hero-section">
      <div className="hero-section__bg image animated fadeInDown">
        <img
          src={urlFor(image).width(1600).auto('format').url()}
          alt="Portrait"
          srcSet={`
            ${urlFor(image).width(375).auto('format').url()} 375w,
            ${urlFor(image).width(1200).auto('format').url()} 768w,
            ${urlFor(image).width(2000).auto('format').url()} 1280w
          `}
          sizes="(max-width: 375px) 375px, (max-width: 768px) 768px, 1280px"
        />
      </div>
      <div className="hero-section__content">
        <div className="container column">
          <div className="columns is-centered">
            <div className="column is-10">
              <article className="hero-section__content__intro animated fadeInLeft delay-250ms">
                <PortableText value={content} />
              </article>
              <a
                target="_blank"
                href={fileUrlFor(config.cv)}
                rel="noreferrer"
                className="field has-addons animated fadeInLeft delay-500ms"
              >
                <div className="control download-icon">
                  <span className="icon is-medium">
                    <FontAwesomeIcon icon={faCloudDownload} />
                  </span>
                </div>
                <div className="control">
                  <div className="button is-dark is-medium">
                    <span>{buttonText}</span>
                  </div>
                </div>
              </a>
              <Socials className="animated fadeInLeft delay-600ms" socials={config.socials} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(HeroSection);

HeroSection.propTypes = {
  content: contentPropType.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: assetPropType.isRequired,
  config: configPropType.isRequired,
};

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import client from '../client';
import { configPropType } from '../prop-types';

import HeroSection from './HeroSection/HeroSection';
import IntroSection from './IntroSection/IntroSection';
import ImageRowSection from './ImageRowSection/ImageRowSection';
import ExperiencesSection from './ExperiencesSection/ExperiencesSection';
import TimelineSection from './TimelineSection/TimelineSection';
import AchievementSection from './AchievementSection/AchievementSection';
import CertificationsSection from './CertificationsSection/CertificationsSection';
import ContactSection from './ContactSection/ContactSection';
import FooterSection from './FooterSection/FooterSection';

const sectionSet = {
  hero: HeroSection,
  intro: IntroSection,
  skill: ExperiencesSection,
  imageRow: ImageRowSection,
  timeline: TimelineSection,
  achievement: AchievementSection,
  certifications: CertificationsSection,
  contact: ContactSection,
  footer: FooterSection,
};

function DynamicSection({ id, config }) {
  const [{ _type: type, ...sectionProps }, setSectionProps] = useState({});

  useEffect(() => {
    client.fetch(`*[_id == "${id}"][0]`).then(setSectionProps);
  }, []);

  const Section = sectionSet[type];

  return Section ? <Section config={config} {...sectionProps} /> : null;
}

export default memo(DynamicSection);

DynamicSection.propTypes = {
  id: PropTypes.string.isRequired,
  config: configPropType.isRequired,
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { iconPropType } from '../../prop-types';

export default function Icon({ icon }) {
  return (
    <FontAwesomeIcon icon={icon} />
  );
}

Icon.propTypes = {
  icon: iconPropType.isRequired,
};

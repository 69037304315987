import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { tryGetFile } from '@sanity/asset-utils';

const CONFIG = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: '2023-01-18',
};

const client = sanityClient(CONFIG);

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}

export function fileUrlFor({ asset: { _ref: ref } }) {
  return tryGetFile(ref, CONFIG).asset.url;
}

export default client;

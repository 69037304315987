import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { PortableText } from '@portabletext/react';
import { useContentReplacer } from '../../utils/replace';
import { configPropType, contentPropType, iconPropType } from '../../prop-types';
import Icon from '../Icon/Icon';

import './IntroSection.scss';

function IntroSection({ content: _content, hobbies, config }) {
  const age = useMemo(() => dayjs().diff(config.dateOfBirth, 'years'), []);
  const content = useContentReplacer(_content, { age });

  return (
    <section className="section pt-0 animated fadeInUp">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-10-tablet is-8-desktop">
            <div className="card intro-card">
              <article className="card-content content">
                <PortableText value={content} />
              </article>
            </div>
          </div>
          <div className="column is-12">
            <div className="hobbies">
              {hobbies.map(({ title, icon, _key: key }) => (
                <div className="hobbies__item" key={key}>
                  <div className="hobbies__item__icon">
                    <Icon icon={icon} />
                  </div>
                  <p className="hobbies__item__title">
                    {title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(IntroSection);

IntroSection.propTypes = {
  content: contentPropType.isRequired,
  hobbies: PropTypes.arrayOf(PropTypes.shape({
    icon: iconPropType,
    title: PropTypes.string,
    _key: PropTypes.string,
    _type: PropTypes.string,
  })).isRequired,
  config: configPropType.isRequired,
};

import { useMemo } from 'react';

export const replaceInText = (string, variables) => string.replace(/{{(\w+)}}/g, (m, m1) => variables[m1] || m);

export const useContentReplacer = (contents, variables) => useMemo(() => contents.map((content) => ({
  ...content,
  children: content.children.map((child) => ({
    ...child,
    text: replaceInText(child.text, variables),
  })),
})), [contents, variables]);

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PortableText } from '@portabletext/react';
import dayjs from 'dayjs';
import Heading from '../Heading/Heading';
import './TimelineSection.scss';
import { fileUrlFor, urlFor } from '../../client';
import { GlobalContext } from '../../context';
import { assetPropType, contentPropType } from '../../prop-types';

const formatDateFromTo = (_from, _to, active = false) => {
  const from = dayjs(_from).format('YYYY');
  const to = active ? 'Present' : dayjs(_to).format('YYYY');

  return `${from} - ${to}`;
};

export default function TimelineSection({ title, content, employments }) {
  const { state, set } = useContext(GlobalContext);

  useEffect(() => {
    if (employments && !state.experience) {
      const firstEmploymentStartDate = employments.map(({ employmentStart }) => employmentStart).sort()[0];
      set('experience', dayjs().diff(firstEmploymentStartDate, 'years'));
    }
  }, [employments]);

  return (
    <section className="section">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-4">
            <Heading position="left" sticky title={title} content={content} />
          </div>
          <div className="column is-8">
            {employments.map(({
              _key: key,
              companyName,
              companyLogo,
              employmentStart,
              employmentEnd,
              workingHere,
              jobTitle,
              website,
              referenceLetter,
              content: jobDescription,
            }) => (
              <div className="timeline-item" key={key}>
                <div className="timeline-item__logo">
                  <img src={urlFor(companyLogo).width(100).auto('format').url()} alt={companyName} />
                </div>
                <div className="timeline-item__content card">
                  <div className="timeline-item__date">
                    {formatDateFromTo(employmentStart, employmentEnd, workingHere)}
                  </div>
                  <article className="card-content">
                    <header className="timeline-item__content__heading">
                      <div className="timeline-item__content__heading__logo">
                        <img src={urlFor(companyLogo).width(100).auto('format').url()} alt={companyName} />
                      </div>
                      <div>
                        <p className="timeline-item__content__title">{companyName}</p>
                        <p className="timeline-item__content__subtitle">{jobTitle}</p>
                      </div>
                    </header>

                    <div className="content">
                      <PortableText value={jobDescription} />
                    </div>
                    <div className="buttons">
                      <a target="_blank" href={website} className="button is-light is-outlined" rel="noreferrer">Website</a>
                      {referenceLetter && (
                        <a
                          target="_blank"
                          href={fileUrlFor(referenceLetter)}
                          className="button is-primary"
                          rel="noreferrer"
                        >
                          Reference Letter
                        </a>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

TimelineSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: contentPropType.isRequired,
  employments: PropTypes.arrayOf(PropTypes.shape({
    _key: PropTypes.string,
    _type: PropTypes.string,
    content: contentPropType,
    companyLogo: assetPropType,
    companyName: PropTypes.string,
    employmentEnd: PropTypes.string,
    employmentStart: PropTypes.string,
    jobTitle: PropTypes.string,
    website: PropTypes.string,
  })).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import './ContactSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '../Heading/Heading';
import { configPropType, contentPropType } from '../../prop-types';
import Socials from '../Socials/Socials';
import { fileUrlFor } from '../../client';

export default function ContactSection(props) {
  const { title, content, config } = props;

  return (
    <div className="section section--full-height">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-5 is-flex is-flex-direction-column is-align-items-center">
            <Heading title={title} content={content} />

            <div className="contact-email">
              <a className="contact-email__link" href={`mailto:${config.email}`}>{config.email}</a>
            </div>

            <p className="mb-4">or</p>

            <Socials socials={config.socials} />
            <a
              target="_blank"
              href={fileUrlFor(config.cv)}
              rel="noreferrer"
              className="button is-outlined is-light"
            >
              <span className="icon is-small mr-2">
                <FontAwesomeIcon icon={faCloudDownload} />
              </span>
              <span>
                Download CV
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

ContactSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: contentPropType.isRequired,
  config: configPropType.isRequired,
};

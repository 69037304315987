import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import './AchievementSection.scss';
import CountUp from 'react-countup';
import { GlobalContext } from '../../context';
import { replaceInText } from '../../utils/replace';
import Icon from '../Icon/Icon';
import { iconPropType } from '../../prop-types';

function AchievementSection({ achievements }) {
  const { state } = useContext(GlobalContext);

  return (
    <section className="section pl-0 pr-0">
      <div className="achievement-section">
        <div className="container column">
          <div className="columns is-centered is-multiline">
            <div className="column is-12">
              <article className="achievements">
                {achievements.map(({ highlight, content, icon }) => (
                  <div className="achievement-item" key={`${icon}-${highlight}-${content.toLowerCase()}`}>
                    <div className="achievement-item__icon">
                      <Icon icon={icon} />
                    </div>
                    <CountUp start={0} end={replaceInText(highlight, { experience: state.experience })} delay={0}>
                      {({ countUpRef }) => (
                        <h3 className="achievement-item__highlight" ref={countUpRef}>0</h3>
                      )}
                    </CountUp>
                    <p className="achievement-item__content">{content}</p>
                  </div>
                ))}
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(AchievementSection);

AchievementSection.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.shape({
    icon: iconPropType,
    highlight: PropTypes.string,
    content: PropTypes.string,
  })).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { socialsPropType } from '../../prop-types';
import Icon from '../Icon/Icon';
import './Socials.scss';

export default function Socials({ socials, className }) {
  return (
    <div className={`buttons social-buttons ${className}`}>
      {socials.map(({ link, icon, title }) => (
        <a
          key={`social-${link}`}
          className="button is-rounded is-dark"
          href={link}
          title={title}
          aria-label={title}
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon is-small">
            <Icon icon={icon} />
          </span>
        </a>
      ))}
    </div>
  );
}

Socials.propTypes = {
  socials: socialsPropType,
  className: PropTypes.string,
};

Socials.defaultProps = {
  socials: [],
  className: '',
};

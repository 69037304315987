import React, { useEffect, useMemo, useState } from 'react';
import DynamicSection from './components/DynamicSection';
import client from './client';
import { GlobalContext, initialValue } from './context';

export default function App() {
  const [blocks, setBlocks] = useState([]);
  const [siteConfig, setSiteConfig] = useState({});
  const [globalState, setGlobalState] = useState(initialValue.state);

  useEffect(() => {
    client
      .fetch('*[_type == "page" && (path == "/")][0]{blocks[]}')
      .then((data) => setBlocks(data.blocks));

    client
      .fetch('*[_type == "siteSettings"][0]')
      .then((data) => setSiteConfig(data));
  }, []);

  const globalContext = useMemo(() => ({
    state: globalState,
    set: (key, value) => {
      setGlobalState({ ...globalState, [key]: value });
    },
  }), [globalState]);

  return (
    <GlobalContext.Provider value={globalContext}>
      {blocks?.map(({ _ref: id }) => <DynamicSection config={siteConfig} key={id} id={id} />)}
    </GlobalContext.Provider>
  );
}

import React from 'react';
import './FooterSection.scss';
import { PortableText } from '@portabletext/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Socials from '../Socials/Socials';
import { configPropType, contentPropType } from '../../prop-types';

export default function FooterSection({ content, config }) {
  return (
    <footer className="section pl-0 pr-0 has-background-dark footer">
      <div className="container column">
        <div className="columns is-centered is-multiline">
          <div className="column is-5 is-flex is-align-items-center">
            <div className="content">
              <PortableText value={content} />
            </div>
          </div>
          <div className="column is-4">
            <Socials socials={config.socials} />
            <button className="button is-outlined is-light" type="button">
              <span className="icon is-small mr-2">
                <FontAwesomeIcon icon="cloud-download" />
              </span>
              <span>
                Download CV
              </span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}

FooterSection.propTypes = {
  content: contentPropType.isRequired,
  config: configPropType.isRequired,
};
